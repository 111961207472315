import React from "react";
import { NavLink } from "react-router-dom";
import "./header.css";

const generalLinks = [
  { name: "Home", id: "home", path: "/" },
  { name: "Programme", id: "programme", path: "/programme" },
  { name: "About us", id: "about", path: "/about" },
];

const navLinks = generalLinks;

const header = () => {
  return (
    <div className="header">
      <img
        className="logo"
        src={`${process.env.PUBLIC_URL}/Logo.svg`}
        alt="logo"
      />
      <div className="nav">
        {navLinks.map((link) => (
          <NavLink className="nav-item" key={link.id} to={link.path}>
            {link.name}
          </NavLink>
        ))}
      </div>
      <div className="action-buttons">
        <button className="action-button login">Log In</button>
        <button className="action-button tryForFree">Try For Free</button>
      </div>
    </div>
  );
};

export default header;
