import React from "react";
import Header from "../Header/header";
import "./home.css";

const home = () => {
  return (
    <>
      <Header />

      <div className="hero container">
        <div className="home-hero-content">
          <div className="line-1">
            <div className="line"></div>
            <p className="heartsOnFloor">HEARTS ON FLOOR</p>
          </div>
          <div className="line-2">
            Get Your Athlete Score And Personalised plans
          </div>
          <div>
            <button className="hero-action-button">
              START 7-day FREE TRIAL
            </button>
          </div>
        </div>
      </div>
      <div className="quiz">
        <div className="quiz-container">
          <div className="quiz-content">
            <p className="quiz-line">Not sure which program suits you best?</p>
            <div>
              <button className="quiz-button">Take a quick quiz</button>
            </div>
          </div>
        </div>
      </div>
      <div className="programme">
        <div className="programme-content">
          <p className="programme-heading">Our popular Programmes</p>
          <div className="programme-grid">
            <div class="item item1">
              <img
                className="grid-img"
                src={`${process.env.PUBLIC_URL}/fusion.png`}
                alt="home-background-img"
              />
              <div className="overlay item1">
                <p className="overlay-text item1">FUSION</p>
                <img
                  className="overlay-red-line item1"
                  src={`${process.env.PUBLIC_URL}/red-line.svg`}
                  alt="home-background-img"
                />
              </div>
            </div>
            <div class="item item2">
              <img
                className="grid-img"
                src={`${process.env.PUBLIC_URL}/homeWorkout.png`}
                alt="home-background-img"
              />
              <div className="overlay item2">
                <p className="overlay-text item2">HOME WORKOUT</p>
                <img
                  className="overlay-red-line item2"
                  src={`${process.env.PUBLIC_URL}/red-line.svg`}
                  alt="home-background-img"
                />
              </div>
            </div>
            <div class="item item4">
              <img
                className="grid-img"
                src={`${process.env.PUBLIC_URL}/Gymnastics.png`}
                alt="home-background-img"
              />
              <div className="overlay item4">
                <p className="overlay-text item4">Gymnastics bias</p>
                <img
                  className="overlay-red-line item4"
                  src={`${process.env.PUBLIC_URL}/red-line.svg`}
                  alt="home-background-img"
                />
              </div>
            </div>
            <div className="grid-last-row">
              <div class="item item3">
                <img
                  className="grid-img"
                  src={`${process.env.PUBLIC_URL}/Weightlifting.png`}
                  alt="home-background-img"
                />
                <div className="overlay item3">
                  <p className="overlay-text item3">
                    Weightlifting techincal bias
                  </p>
                  <img
                    className="overlay-red-line item3"
                    src={`${process.env.PUBLIC_URL}/red-line.svg`}
                    alt="home-background-img"
                  />
                </div>
              </div>
              <div class="item item5">
                <p className="program-grid-item5-line">
                  Discover your perfect fitness routine
                </p>
                <div>
                  <button className="program-grid-item5-button">
                    Explore More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="home-bg-img"
          src={`${process.env.PUBLIC_URL}/home-bg-img.png`}
          alt="home-background-img"
        />
      </div>
    </>
  );
};

export default home;
